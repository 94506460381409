<template>
  <div data-app v-if="page.id">
    <v-row>
      <v-col md="9" offset-xl="2" xl="6">
        <div class="card card-custom card-stretch">
          <div class="card-body">
            <v-row class="mt-5">
              <v-col cols="8">
                <label>Brand Assets</label>
              </v-col>
            </v-row>

            <Chapter
              :chapters="page.chapters"
              :pageId="page.id"
              @change="fetchPage"
            />
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module";
import { BRAND_ASSETS_DETAIL } from "@/core/services/store/brand_assets.module";
// import { UPDATE_PAGES } from "@/core/services/store/pages.module";
// import { UPDATE_BLOCK } from "@/core/services/store/blocks.module";

import { mapActions } from "vuex";

import Chapter from "@/view/components/Chapter.vue";
// import TextBlock from "@/view/components/Block/TextBlock.vue";

export default {
  components: {
    Chapter,
    // TextBlock,
  },

  data: () => ({
    page: {},
  }),

  mounted() {
    this.fetchPage();
  },

  methods: {
    ...mapActions("snackbar", ["showSnack"]),

    showLoading() {
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
    },

    hideLoading() {
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    },

    // submit() {
    //   this.$store
    //     .dispatch(UPDATE_PAGES, {
    //       id: this.page.id,
    //       data: {
    //         page_title: this.page.page_title,
    //         title: this.page.title,
    //       },
    //     })
    //     .then(async () => {
    //       this.page.blocks.forEach((block) => {
    //         var dataBlock = {};

    //         if (block.block_type == "TextBlock")
    //           dataBlock["text"] = this.page.blocks[0].content.text;

    //         this.$store.dispatch(UPDATE_BLOCK, {
    //           id: block.id,
    //           data: {
    //             block: dataBlock,
    //           },
    //         });
    //       });
    //     })
    //     .then(() => {
    //       this.fetchPage();
    //       this.showSnack({
    //         text: "Pagina Brand Assets salvata con successo.",
    //         color: "success",
    //         timeout: 3500,
    //       });
    //     })
    //     .catch((error) => {
    //       alert(error.message);
    //     });
    // },

    fetchPage() {
      this.showLoading();
      this.$store.dispatch(BRAND_ASSETS_DETAIL).then((data) => {
        if (data.page != null) {
          this.page = data.page;
        }
        this.hideLoading();
      });
    },
  },
};
</script>
